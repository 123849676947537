<template>
  <main id="main">
    <section>
      <div class="titulo">
        <div class="margem container">
          <h2>Minhas Tarefas</h2>
        </div>
      </div>
      <div class="margem container">
        <fieldset class="grid-4 bloco2 margem">
          <div class="col-2">
            <label>Responsável</label>
            <select :disabled="subordinados.length <= 1" v-model="usuarioTarefas">
              <option v-if="subordinados.length > 1" :value="subordinados.map((user) => ({ valor: user.usuario_id }))">
                Todos</option>
              <option v-for="item in subordinados" :key="item.usuario_id" :value="[{ valor: item.usuario_id }]">{{
                item.nome }}</option>
            </select>
          </div>
          <!-- <div>
                <label>Período de</label>
                <input :required="true" v-model="periodoInicio" :disabled="buscarTarefasSemData == true"
                    type="date">
            </div> -->
          <div>
            <label>Fim previsto até</label>
            <input v-model="periodoFim" :disabled="buscarTarefasSemData == true" type="date" />
          </div>
          <div style="align-content: end">
            <div class="tags" style="margin-top: 0.5rem">
              <a @click="(buscarTarefasSemData = !buscarTarefasSemData), definirPeriodo()"
                :class="buscarTarefasSemData == true ? 'ativo' : ''" style="cursor: pointer"> Tarefas sem fim
                previsto</a>
            </div>
          </div>
          <div>
            <label>Origem</label>
            <multiselect v-model="origem" :selectLabel="''" label="label" track-by="label" :hideSelected="true"
              :allowEmpty="false" :selectedLabel="''" :deselectLabel="''" :searchable="false" :multiple="true" :options="[
                { valor: 999, label: 'Projetos' },
                { valor: 1000, label: 'PAP' },
                { valor: 1, label: 'TNC' },
                { valor: 3, label: 'RAP' },
                { valor: 4, label: 'OS' },
                { valor: 5, label: 'Plano de Ação' },
              ]" :close-on-select="false">
            </multiselect>
          </div>
          <div>
            <label>Status da origem</label>
            <multiselect v-model="statusOrigem" :selectLabel="''" label="valor" track-by="valor" :hideSelected="true"
              :allowEmpty="false" :selectedLabel="''" :deselectLabel="''" :searchable="false" :multiple="true"
              :options="[{ valor: 'Aprovado' }, { valor: 'Em andamento' }, { valor: 'Concluído' }, { valor: 'Suspenso' }]"
              :close-on-select="false">
            </multiselect>
          </div>
          <div>
            <label>Status da tarefa</label>
            <multiselect :selectLabel="''" label="valor" track-by="valor" :hideSelected="true" :allowEmpty="false"
              :selectedLabel="''" :deselectLabel="''" :searchable="false" :multiple="true" v-model="statusTarefas"
              :options="[{ valor: 'Pendente' }, { valor: 'Em andamento' }, { valor: 'Concluído' }, { valor: 'Cancelada' }]"
              :close-on-select="false">
            </multiselect>
          </div>
          <div style="text-align: end; align-content: end" class=""><button @click="getTarefas">Buscar</button></div>
        </fieldset>
        <br />
        <div class="bloco margem">
          <div style="display: flex; justify-content: space-between">
            <h5>
              <span style="display: flex">
                <span class="hPoints bg-alerta">{{ somatoriaHP[0] }}</span>
                <span class="hPoints bg-ok">{{ somatoriaHP[1] }}</span>
                <span class="hPoints bg-sucesso">{{ somatoriaHP[2] }}</span>
              </span>
            </h5>
            <div>
              <strong> Mostrando {{ tarefas.length }} {{ tarefas.length == 1 ? "tarefa" : "tarefas" }} </strong>
            </div>
          </div>
          <table class="tabela">
            <tbody>
              <tr>
                <th scope="col" style="width: 10%" @click="ordernarColuna('pcm_codigo')" class="header">
                  Nº <span v-if="activeFilter == 'pcm_codigo'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="width: 10%" @click="ordernarColuna('responsavel_nome')" class="header">
                  Responsável <span v-if="activeFilter == 'responsavel_nome'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="width: 18%">
                  Origem
                </th>
                <th scope="col" style="width: 17%">Descrição</th>
                <th scope="col" style="width: 5%" @click="ordernarColuna('HP')" class="header">
                  H.P. <span v-if="activeFilter == 'HP'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="width: 10%" @click="ordernarColuna('dtFim')" class="header">
                  Fim Previsto <span v-if="activeFilter == 'dtFim'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="width: 10%" @click="ordernarColuna('dtFimReal')" class="header">
                  Fim Real <span v-if="activeFilter == 'dtFimReal'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
                <th scope="col" style="width: 20%" @click="ordernarColuna('status')" class="header">
                  Status <span v-if="activeFilter == 'status'"><i
                      :class="isAscending ? 'bi bi-caret-down-fill' : 'bi bi-caret-up-fill'"></i></span>
                </th>
              </tr>
              <tr style="text-align: center" v-for="item in tarefasFiltradas" :key="item">
                <td style="vertical-align: middle">{{ item.codigo }}</td>
                <td style="vertical-align: middle">{{ nomeEsobrenome(item.responsavel_nome) }}</td>
                <td style="vertical-align: middle">
                  <div style="display: flex; flex-flow: column">
                    <span>{{ item.pcm_codigo }}</span><span>{{ item.nome }}</span>
                  </div>
                </td>
                <td style="text-align: left; cursor: pointer">
                  <div :title="item.descricao"
                    style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis">
                    {{ item.descricao }}
                  </div>
                </td>
                <td style="vertical-align: middle">
                  <span>
                    {{ item.HP }}
                  </span>
                </td>
                <td style="vertical-align: middle">
                  {{ item.dtFim ?
                    `${formatarDataHora(item.dtFim).split("-")[2]}/${formatarDataHora(item.dtFim).split("-")[1]}/${formatarDataHora(item.dtFim).split("-")[0]}`
                    : "-" }}
                </td>

                <td style="vertical-align: middle">
                  {{ item.dtFimReal ?
                    `${formatarDataHora(item.dtFimReal).split("-")[2]}/${formatarDataHora(item.dtFimReal).split("-")[1]}/${formatarDataHora(item.dtFimReal).split("-")[0]}`
                    : "-" }}
                </td>

                <td style="vertical-align: middle">
                  <div style="position: relative">
                    <v-progress-linear :color="item.status == 'Cancelada'
                      ? 'red'
                      : item.status == 'Concluído'
                        ? 'var(--cor-sucesso)'
                        : item.status == 'Pendente'
                          ? 'var(--cor-alerta)'
                          : item.status == 'Em andamento' || 'Em andamento 25%' || 'Em andamento 50%' || 'Em andamento 75%'
                            ? 'var(--cor-ok)'
                            : 'red'
                      " :model-value="item.status == 'Concluído' || item.status == 'Cancelada' ? 100 : item.status == 'Pendente' ? 100 : item.status.split(' ')[2] ? item.status.split(' ')[2].slice(0, 2) : 2
                        " style="border: 1px solid var(--cor-fonte-fraca); border-radius: 25px; height: 2rem">
                    </v-progress-linear>
                    <select :title="item.status"
                      :style="{ color: item.status == 'Concluído' ? 'var(--cor-bg)' : 'black' }" style="
                        border: none;
                        padding-left: 0.5rem;
                        position: absolute;
                        top: 0;
                        left: 0rem;
                        outline: none;
                        border-radius: 25px;
                        height: 2rem;
                        text-align: center;
                        background-color: transparent;
                        padding-top: 0rem;
                        padding-bottom: 0rem;
                      " @change="
                        item.tipo == 999
                          ? editarBacklog('status', item.id, item.status)
                          : item.tipo == 1000
                            ? editarBacklogPlanodeAção('status', item.id, item.status)
                            : editarBacklogProtocolo('status', item.id, item.status)
                        " v-model="item.status">
                      <option value="" hidden></option>
                      <option style="color: rgb(255, 145, 0)" value="Pendente">A iniciar</option>
                      <option style="color: rgb(0, 47, 255)" :value="'Em andamento'">Em andamento 0%</option>
                      <option style="color: rgb(0, 47, 255)">Em andamento 25%</option>
                      <option style="color: rgb(0, 47, 255)">Em andamento 50%</option>
                      <option style="color: rgb(0, 47, 255)">Em andamento 75%</option>
                      <option style="color: rgb(0, 192, 0)">Concluído</option>
                      <option style="color: red">Cancelada</option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <gestãoDeEntregas /> -->
    </section>
  </main>
</template>

<script>
import { api } from "roboflex-thalamus-request-handler";
import { getDados } from "@/services/serviceFuncionalidades";
import { consultarSetores } from "@/services/usuario-setor";
import { sso } from "roboflex-thalamus-sso-lib";
import Multiselect from "vue-multiselect";
import serviceFunções from "@/services/serviceFunções";
// import gestãoDeEntregas from "./gestãoDeEntregas.vue";

export default {
  name: "gestaoTarefas",

  components: {
    Multiselect,
    // gestãoDeEntregas
  },

  setup() {
    const formatarDataHora = (valor) => {
      return serviceFunções.formatarDataHora(valor);
    };

    const nomeEsobrenome = (nome) => {
      return serviceFunções.nomeEsobrenome(nome);
    };

    return {
      formatarDataHora,
      nomeEsobrenome,
    };
  },

  data() {
    return {
      tarefasFiltradas: "",
      tarefasSemDataFiltradas: "",
      buscarTarefasSemData: false,
      idUsuario: null,
      dadosUsuario: null,
      subordinados: [],

      usuarioTarefas: null,
      statusOrigem: [{ valor: "Aprovado" }, { valor: "Em andamento" }, { valor: "Concluído" }],
      statusTarefas: [{ valor: "Pendente" }, { valor: "Em andamento" }],
      periodoInicio: "",
      periodoFim: "",
      // periodoFim: this.getMondayAndFriday().Sexta,

      origem: [
        {
          valor: 999,
          label: "Projetos",
        },
        {
          valor: 1000,
          label: "PAP",
        },
        {
          valor: 1,
          label: "TNC",
        },
        {
          valor: 3,
          label: "RAP",
        },
        {
          valor: 4,
          label: "OS",
        },
        {
          valor: 5,
          label: "Plano de Ação",
        },
      ],

      usuarios: null,
      showComentarioTarefa: false,
      tarefas: [],
      tarefasSemdata: [],
      somatoriaHP: [0, 0, 0],

      isAscending: true,
      activeFilter: "none",
    };
  },
  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.idUsuario = usuarioLogado.id;
    this.usuarioTarefas = [{ valor: usuarioLogado.id }];
  },

  mounted() {
    this.getSetores();
    this.carregarPermissoes();
    this.getTarefas();
  },

  methods: {
    definirPeriodo() {
      if (this.buscarTarefasSemData == false) {
        // this.periodoFim = this.getMondayAndFriday().Sexta;
        this.periodoFim = "";
      } else if (this.buscarTarefasSemData == true) {
        this.periodoInicio = "";
        this.periodoFim = "semData";
      }
    },

    somarHP() {
      let somaPendentes = 0;
      let somaEmAndamento = 0;
      let somaConcluidos = 0;

      this.tarefas.forEach((item) => {
        if (item.status == "Pendente") {
          if (item.HP) {
            somaPendentes += parseInt(item.HP);
          }
        }
        if (item.status == "Em andamento" || item.status == "Em andamento 25%" || item.status == "Em andamento 50%" || item.status == "Em andamento 75%") {
          if (item.HP) {
            somaEmAndamento += parseInt(item.HP);
          }
        }
        if (item.status == "Concluído") {
          if (item.HP) {
            somaConcluidos += parseInt(item.HP);
          }
        }
      });

      this.somatoriaHP = [somaPendentes, somaEmAndamento, somaConcluidos];
    },

    getMondayAndFriday() {
      const today = new Date();
      const day = today.getDay();
      const diffToMonday = today.getDate() - day + (day === 0 ? -6 : 1); // Ajuste para que domingo seja -6
      const monday = new Date(today.setDate(diffToMonday));
      const friday = new Date(monday);
      friday.setDate(monday.getDate() + 4); // Adiciona 4 dias para obter sexta-feira

      return {
        Segunda: monday.toISOString().split("T")[0], // Formata a data para YYYY-MM-DD
        Sexta: friday.toISOString().split("T")[0], // Formata a data para YYYY-MM-DD
      };
    },

    editarBacklogPlanodeAção(itemAlterado, idBacklog, novoValor) {
      api
        .put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
          usuario_id: this.idUsuario,
          [itemAlterado]: novoValor,
        })
        .then(() => {
          if (itemAlterado == "status") {
            return this.definirInicioFimRealPlanodeAção(idBacklog, novoValor);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    editarBacklogProtocolo(itemAlterado, idTarefa, novoValor) {
      api
        .put(`protocolo/tarefa/atualizar/${idTarefa}`, {
          usuario_id: this.idUsuario,
          [itemAlterado]: novoValor,
          // + (itemAlterado == 'dtInicio' ? ' 00:00:00': null)
        })
        .then(() => {
          if (itemAlterado == "status") {
            return this.definirInicioFimRealProtocolo(idTarefa, novoValor);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    definirInicioFimRealProtocolo(idTarefa, status) {
      let data = new Date();
      let ano = data.getFullYear();
      let mes = data.getMonth() + 1;
      if (mes < 10) {
        mes = "0" + mes;
      }
      let dia = data.getDate();
      if (dia < 10) {
        dia = "0" + dia;
      }
      data = ano + "-" + mes + "-" + dia;

      if (status == "Em andamento" || status == "Em andamento 25%" || status == "Em andamento 50%" || status == "Em andamento 75%") {
        api
          .put(`protocolo/tarefa/atualizar/${idTarefa}`, {
            usuario_id: this.idUsuario,
            dtInicioReal: data,
            dtFimReal: null,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idTarefa).dtInicioReal = data;
          });
      }
      if (status == "Concluído") {
        api
          .put(`protocolo/tarefa/atualizar/${idTarefa}`, {
            usuario_id: this.idUsuario,
            dtFimReal: data,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idTarefa).dtFimReal = data;
          });
      }
      if (status == "Pendente") {
        api
          .put(`protocolo/tarefa/atualizar/${idTarefa}`, {
            usuario_id: this.idUsuario,
            dtInicioReal: null,
            dtFimReal: null,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idTarefa).dtInicioReal = null;
            this.tarefas.find((item) => item.id === idTarefa).dtFimReal = null;
          });
      }
    },

    definirInicioFimRealPlanodeAção(idBacklog, status) {
      let data = new Date();
      let ano = data.getFullYear();
      let mes = data.getMonth() + 1;
      if (mes < 10) {
        mes = "0" + mes;
      }
      let dia = data.getDate();
      if (dia < 10) {
        dia = "0" + dia;
      }
      data = ano + "-" + mes + "-" + dia;

      if (status == "Em andamento" || status == "Em andamento 25%" || status == "Em andamento 50%" || status == "Em andamento 75%") {
        api
          .put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtInicioReal: data,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtInicioReal = data;
          });
      }
      if (status == "Concluído") {
        api
          .put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtFimReal: data,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtFimReal = data;
          });
      }
      if (status == "Pendente") {
        api
          .put(`planoAcaoTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtInicioReal: null,
            dtFimReal: null,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtInicioReal = null;
            this.tarefas.find((item) => item.id === idBacklog).dtFimReal = null;
          });
      } else {
        return;
      }
    },

    editarBacklog(itemAlterado, idBacklog, novoValor) {
      api
        .put(`sprintTarefa/atualizar/${idBacklog}`, {
          usuario_id: this.idUsuario,
          [itemAlterado]: novoValor,
        })
        .then(() => {
          if (itemAlterado == "status") {
            return this.definirInicioFimReal(idBacklog, novoValor);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    definirInicioFimReal(idBacklog, status) {
      let data = new Date().toISOString().split("T")[0] + " " + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;

      if (status == "Em andamento" || status == "Em andamento 25%" || status == "Em andamento 50%" || status == "Em andamento 75%") {
        // axios.put(`http://10.100.0.5:8000/api/sprintTarefa/atualizar/${idBacklog}`, {
        api
          .put(`sprintTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtFimReal: null,
            dtInicioReal: data,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtFimReal = null;
            this.tarefas.find((item) => item.id === idBacklog).dtInicioReal = data;
          });
      }
      if (status == "Concluído") {
        api
          .put(`sprintTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtFimReal: data,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtFimReal = data;
          });
      }
      if (status == "Pendente") {
        api
          .put(`sprintTarefa/atualizar/${idBacklog}`, {
            usuario_id: this.idUsuario,
            dtInicioReal: null,
            dtFimReal: null,
          })
          .then(() => {
            this.tarefas.find((item) => item.id === idBacklog).dtInicioReal = null;
            this.tarefas.find((item) => item.id === idBacklog).dtFimReal = null;
          });
      } else {
        return;
      }
    },

    getTarefas() {
      api
        .post(`gestao/tarefasUsuario`, {
          dtFim: this.periodoFim,
          idUser: this.usuarioTarefas,
          statusProjetoPa: this.statusOrigem,
          statusTarefa: this.statusTarefas,
        })
        .then((response) => {
          this.tarefas = response.data.map((item) => ({
            tipo: item.tipo,
            id: item.id,
            nome: item.nome,
            descricao: item.descricao,
            status: item.status,
            HP: item.HP,
            dtFim: item.dtFim ? item.dtFim.slice(0, 10) : null,
            dtFimReal: item.dtFimReal ? item.dtFimReal.slice(0, 10) : null,
            responsavel_nome: item.responsavel_nome,
            codigo: item.codigo,
            pcm_codigo: item.pcm_codigo,
          }));

          this.tarefas = this.tarefas.sort((a, b) => {
            const order = {
              Andamento: 1,
              Pendente: 2,
              Concluído: 3,
              Cancelada: 4,
            };
            const orderA = order[a.status] || 0;
            const orderB = order[b.status] || 0;
            if (orderA < orderB) {
              return -1;
            }
            if (orderA > orderB) {
              return 1;
            }
            return 0;
          });
          var origem = this.origem.map((item) => item.valor);

          this.tarefas = this.tarefas.filter((tarefa) => origem.includes(tarefa.tipo));
          this.tarefasFiltradas = this.tarefas
          this.somarHP();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    carregarPermissoes() {
      var id = this.idUsuario;
      getDados(id)
        .then((dadosUsuario) => {
          this.dadosUsuario = dadosUsuario;
        })
        .catch((error) => {
          console.error(error);
          console.log("Falha ao colher dados do usuario");
        });

      api
        .post(`setor/responsavel/usuario`, {
          usuario_id: id,
        })
        .then((response) => {
          this.subordinados = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getSetores() {
      try {
        const { usuarios } = await consultarSetores();
        this.usuarios = usuarios;
      } catch (error) {
        console.error(error);
      }
    },

    ordernarColuna(coluna) {
      this.activeFilter = coluna;
      switch (coluna) {
        case "pcm_codigo":
          this.ordernarString(coluna);
          break;
        case "responsavel_nome":
          this.porStatus();
          break;
        case "status":
          this.porStatus();
          break;
        case "dtFim":
          this.porData(coluna);
          break;
        case "dtFimReal":
          this.porData(coluna);
          break;
        case "HP":
          this.porHP();
          break;
        default:
          break;
      }
      this.isAscending = !this.isAscending;
    },

    ordernarString(coluna) {
      this.tarefasFiltradas.sort((a, b) => {
        const valorA = a[coluna] ?? "z";
        const valorB = b[coluna] ?? "z";

        if (this.isAscending) return valorA.localeCompare(valorB);
        else return valorB.localeCompare(valorA);
      });
    },

    porData(coluna) {
      this.tarefasFiltradas.sort((a, b) => {
        const dataInicioA = new Date(a[coluna]);
        const dataInicioB = new Date(b[coluna]);
        if (this.isAscending) return dataInicioA - dataInicioB;
        else return dataInicioB - dataInicioA;
      });
    },

    porStatus() {
      const order = {
        Andamento: 1,
        Pendente: 2,
        Concluído: 3,
        Cancelada: 4,
      };

      this.tarefasFiltradas.sort((a, b) => {
        const orderA = order[a.status] || 0;
        const orderB = order[b.status] || 0;
        if (this.isAscending) {
          if (orderA < orderB) {
            return -1;
          }
          if (orderA > orderB) {
            return 1;
          }
          return 0;
        } else {
          if (orderA > orderB) {
            return -1;
          }
          if (orderA < orderB) {
            return 1;
          }
          return 0;
        }
      });
    },

    porHP() {
      this.tarefasFiltradas.sort((a, b) => {
        const orderA = a.HP ?? 0;
        const orderB = b.HP ?? 0;
        if (this.isAscending) {
          if (orderA < orderB) {
            return -1;
          }
          if (orderA > orderB) {
            return 1;
          }
          return 0;
        } else {
          if (orderA > orderB) {
            return -1;
          }
          if (orderA < orderB) {
            return 1;
          }
          return 0;
        }
      });
    },
  },
};
</script>
<style>
.header {
  cursor: pointer;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect__tags-wrap {
  display: inline;
}

.multiselect__tags {
  min-height: 48px;
  display: block;
  padding: 5px 20px 0 5px;
  border-radius: 6px;
  border: 1px solid var(--cor-separador);
  background: var(--cor-bg);
  font-size: 14px;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 23px 4px 10px;
  border-radius: 14px;
  margin-right: 3px;
  color: var(--cor-fonte);
  line-height: 1;
  background: transparent !important;
  border: 1px solid var(--cor-separador);
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  /* text-overflow: ellipsis; */
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.multiselect__tag-icon::after {
  content: "×";
  color: var(--cor-fonte-fraca);
  font-size: 14px;
}

.multiselect__tag-icon:focus::after,
.multiselect__tag-icon:hover::after {
  color: red;
}

.multiselect__select {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.multiselect__select::before {
  position: relative;
  right: 0;
  top: 65%;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--cor-fonte) transparent transparent transparent;
  content: "";
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: var(--cor-bg);
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid var(--cor-separador);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.multiselect__content::-webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

.multiselect__option::after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  background: var(--cor-primaria-fraca) !important;
  outline: none;
  color: var(--cor-fonte);
}

.multiselect__option--highlight::after {
  content: attr(data-select);
  background: var(--cor-primaria) !important;
  color: var(--cor-fonte);
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
</style>

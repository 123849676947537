<template>
    <main id="main">
        <section>
            <div class="titulo">
                <div class="margem container">
                    <!-- <div class="m-icone direita">
                        <i @click="this.$router.push({ name: 'painel' })" style="font-size: 30px; cursor: pointer;"
                            class="bi bi-kanban botaoAdicionarSprint" :title="'Ir para painel KanBan'"></i>
                    </div> -->
                    <div class="m-icone esquerda"><a @click="this.$router.back();" style="cursor: pointer;"
                            class="icone-voltar m-d"></a></div>
                    <h2>{{ nome }}<span @click="loadPdf"
                            :style="{ 'cursor': (this.pcm_codigo !== 'null') ? 'pointer' : 'default' }">{{
                                pcm_codigo == null ? '' : ` - ${pcm_codigo}` }} <i v-if="pcm_codigo !== null"
                                class="bi bi-file-earmark-pdf"></i></span></h2>
                </div>
            </div>
            <div class="margem container">
                <div>
                    <label>
                        Anexos do projeto: <i title="Adicionar anexo"
                            style="font-size: 15px; cursor: pointer; margin-left: 0.5rem;" class="bi bi-plus-circle"
                            @click="openFileInput"></i>
                    </label>
                    <div class="grid-4">
                        <div class="anexo" v-for="anexo in anexos" :key="anexo.id"
                            @mouseover="mostrarBotaoExcluirAnexo(anexo.id, true)"
                            @mouseleave="mostrarBotaoExcluirAnexo(anexo.id, false)">
                            <a :href="urlFoto.caminhoFoto + anexo.path" target="_blank">
                                {{ anexo.nome }}</a>
                            <i @click="excluirAnexoProjeto(anexo.id)" :id="'botaoExcluir' + anexo.id"
                                style="color: red; visibility: hidden;"
                                class="bi bi-trash-fill botaoAdicionarSprint"></i>
                        </div>
                    </div>
                    <input style="display: none;" ref="fileInput" class="form-control form-control-sm" type="file"
                        @change="handleProjetoFileUpload">
                </div>
                <div class="tags" style="padding: 5px; margin: 10px 0px;" v-if="entregaSelecionada">
                    <div v-for="entrega in entregas" :key="entrega.id"
                        style="display: flex; flex-flow: column; margin-inline: 5px; align-items: center;">
                        <a style="width: 100%;"
                            @click="entregaSelecionada = entrega, this.sprints = null, getBacklogs()"
                            :class="entregaSelecionada.id == entrega.id ? 'ativo' : ''">
                            {{ entrega.nome }}
                        </a>
                        <table style="margin-top: 5px; padding-inline: 1rem;">
                            <tr>
                                <th style="text-align: left !important;">A iniciar</th>
                                <td style="width: 2rem;">{{ entrega.pendente }}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left !important;">Andamento</th>
                                <td style="width: 2rem;">{{ entrega.em_andamento }}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left !important;">Concluídas</th>
                                <td style="width: 2rem;">{{ entrega.concluido }}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left !important;">Total</th>
                                <td style="width: 2rem;">{{ entrega.total }}</td>
                            </tr>
                            <tr>
                                <th style="text-align: left !important;">Atrasadas</th>
                                <td style="width: 2rem;">{{ entrega.atrasadas ?? '-' }}</td>
                            </tr>
                        </table>
                    </div>
                    <!-- v-if="dadosProjeto.gerente_id == idUsuario" -->
                    <a @click="showNovaEntrega = true" v-if="true" ><i
                            class="bi bi-plus-lg"></i></a>
                </div>
                <div class="bloco">
                    <div v-if="true">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="width: 2rem; height: 2rem; margin: 10px 10px 0 0 ;background-color: transparent; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                    class="botaoAdicionarSprint acao-secundaria direita" icon="mdi-dots-horizontal"
                                    v-bind="props"></v-btn>
                            </template>

                            <v-list style="background-color: var(--cor-bg)">
                                <v-list-item>
                                    <v-menu>

                                        <template v-slot:activator="{ props }">
                                            <span style="color: var(--cor-erro);margin: 0.2rem; cursor: pointer;"
                                                v-bind="props">Excluir
                                                entrega</span>
                                        </template>

                                        <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                            <v-list-item>
                                                <div>
                                                    <h3>Tem certeza? Esta ação é Irreversível!</h3>
                                                    <div class="submit">
                                                        <Button @click="excluirEntrega()">Excluir</Button>
                                                        <button class="acao-secundaria">Cancelar</button>
                                                    </div>
                                                </div>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <fieldset v-if="entregaSelecionada" class="grid-4 margem">
                        <div>
                            <label>Nome da entrega</label>
                            <input :disabled="false" type="text"
                                @focusout="atualizarEntrega('nome', entregaSelecionada.nome)"
                                v-model="entregaSelecionada.nome" />
                        </div>
                        <div>
                            <label>
                                Coordenador da entrega
                            </label>
                            <select :disabled="false" v-model="entregaSelecionada.coordenador_id"
                                @change="atualizarEntrega('coordenador_id', entregaSelecionada.coordenador_id)">
                                <option v-for=" item in coordenadores" :key="item.id" :value="item.id">
                                    {{ item.usuario_nome }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label>
                                Inicio da entrega
                            </label>
                            <input :disabled="false" v-model="entregaSelecionada.dtInicio" type="date"
                                @change="atualizarEntrega('dtInicio', entregaSelecionada.dtInicio)">
                        </div>
                        <div>
                            <label style="justify-content: space-between; display: flex;">
                                Conclusão da entrega
                            </label>
                            <input :disabled="false" v-model="entregaSelecionada.dtFim" type="date"
                                @change="atualizarEntrega('dtFim', entregaSelecionada.dtFim)">
                        </div>
                        <div class="col-4">
                            <label>
                                Observação
                            </label>
                            <textarea :disabled="false" v-model="entregaSelecionada.observacao"
                                @change="atualizarEntrega('observacao', entregaSelecionada.observacao)"></textarea>
                        </div>
                    </fieldset>
                </div>
                <br>
                <div>
                    <div v-for="(item, index) in sprints " :key="item">
                        <div class="bloco margem" v-if="this.sprints">
                            <div style="display: flex; justify-content: space-between">
                                <div>
                                    <h5>
                                        <span style="display: flex;">
                                            <span class="hPoints bg-alerta">{{ somarHP(item)[0] }}</span>
                                            <span class="hPoints bg-ok">{{ somarHP(item)[1] }}</span>
                                            <span class="hPoints bg-sucesso">{{ somarHP(item)[2] }}</span>
                                        </span>
                                    </h5>
                                    <i @click="ocultarPlano(item.nome)" :title="'Ocultar Sprint'"
                                        style=" width: 2rem; font-size: 20px; cursor: pointer;"
                                        class="bi bi-eye-slash ocultar botaoAdicionarSprint"
                                        :id="'botaoOcultar' + item.nome"></i>
                                </div>
                                <div>
                                    <h2 style="position: absolute;">{{ item.nome }}</h2>
                                </div>

                                <div style="display: flex; text-align: right; justify-content: right; display: flex"
                                    :style="{ 'visibility': (index == 0 ? 'hidden' : '') }">
                                    <div style="text-align: center;">
                                        <div v-if="item.dtInicio == null && item.dtTermino == null"
                                            style="width: 13rem; border: 1px rgba(255, 145, 0, 0.8) solid; margin-right: 0.5rem; border-radius: 10px;">
                                            <i style=" color: rgba(255, 145, 0, 0.700); font-size: 22px; margin-left: 1rem;"
                                                class="bi bi-stop-circle-fill"></i>
                                        </div>
                                        <div v-if="item.dtInicio !== null && item.dtTermino !== null"
                                            style="width: 13rem; border: 1px rgba(0, 47, 255, 0.8) solid; margin-right: 0.5rem; border-radius: 10px;">
                                            <strong>
                                                {{ abreviarMes(item.dtInicio.slice(0, 10)) }} <i :id="item.id"
                                                    style="font-size: 20px;" class="bi bi-arrow-right"></i>
                                                {{ abreviarMes(item.dtTermino.slice(0, 10)) }}
                                            </strong>
                                            <i style=" color: rgba(0, 47, 255, 0.700); font-size: 22px; margin-left: 1rem;"
                                                class="bi bi-fast-forward-circle-fill"></i>
                                        </div>
                                        <div v-if="item.dtInicio == null && item.dtTermino !== null"
                                            style="width: 13rem; border: 1px rgba(0, 255, 0, 0.700) solid; margin-right: 0.5rem; border-radius: 10px;">
                                            <strong>
                                                {{ abreviarMes(item.dtTermino.slice(0, 10), true) }}
                                            </strong>
                                            <i style=" color: rgba(0, 255, 0, 0.700); font-size: 22px; margin-left: 1rem;"
                                                class="bi bi-check-circle-fill"></i>
                                        </div>
                                    </div>
                                    <v-menu v-if="true">
                                        <template v-slot:activator="{ props }">
                                            <v-btn
                                                style="width: 2rem; height: 2rem; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                icon="mdi-dots-horizontal" class="botaoAdicionarSprint acao-secundaria"
                                                v-bind="props"></v-btn>
                                        </template>

                                        <v-list style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                            <v-list-item style="margin: 0.2rem;"
                                                @click="abrirModalIniciarSprint(item.id)"
                                                :disabled="item.dtInicio == null && item.dtTermino !== null"
                                                :style="{ 'cursor': (item.dtInicio == null && item.dtTermino !== null) ? 'not-allowed' : 'pointer', 'color': (item.dtInicio == null && item.dtTermino !== null) ? 'grey' : 'var(--cor-fonte)' }">
                                                {{ item.dtTermino == null ? 'Iniciar Sprint' : 'Finalizar Sprint' }}
                                                <br />

                                            </v-list-item>
                                            <v-list-item style="margin: 0.2rem; color: var(--cor-fonte);"
                                                v-if="item.dtInicio !== null"
                                                @click="abrirModaeEditarSprint(item.id, false)">
                                                Alterar Datas
                                                <br />
                                            </v-list-item>
                                            <v-list-item>
                                                <v-menu>

                                                    <template v-slot:activator="{ props }">
                                                        <span
                                                            style="color: var(--cor-erro);margin: 0.2rem; cursor: pointer"
                                                            v-bind="props">Excluir
                                                            Sprint</span>
                                                    </template>

                                                    <v-list
                                                        style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                        <v-list-item>
                                                            <div style="max-width: 20rem">
                                                                <h4>Tem certeza? Esta ação é Irreversível!</h4>
                                                                <p>Ao excluir esta sprint, todos os backlogs contidos
                                                                    nela
                                                                    também
                                                                    serão excluídos.</p>
                                                                <div class="">
                                                                    <Button
                                                                        @click="apagarSprint(item.id)">Excluir</Button>
                                                                    <button style="margin-left: 0.5rem;"
                                                                        class="acao-secundaria">Cancelar</button>
                                                                </div>
                                                            </div>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>

                            <div :id="item.nome" class="tabela" style="display: flex; flex-flow: column">
                                <div
                                    style="width: 100%;display: flex ;border-bottom: 1px solid var(--cor-separador); padding-bottom: 0.5rem;">

                                    <div style="width: 10%; text-align: center; padding: none; margin: none;">
                                        <strong>
                                            Código
                                        </strong>
                                    </div>

                                    <div style="width: 20%; text-align: center ;">
                                        <strong>
                                            Descrição
                                        </strong>
                                    </div>

                                    <div style="width: 5%; text-align: center; ">
                                        <strong>
                                            H.P.
                                        </strong>
                                    </div>

                                    <div style="width: 17%; text-align: center;">
                                        <strong>
                                            Responsável
                                        </strong>
                                    </div>

                                    <div style="width: 12.5%; text-align: center; ">
                                        <strong>
                                            Inicio Previsto
                                        </strong>
                                    </div>

                                    <div style="width: 12.5%; text-align: center;">
                                        <strong>
                                            Fim Previsto
                                        </strong>
                                    </div>

                                    <div style="width: 18%; text-align: center;">
                                        <strong>
                                            Status
                                        </strong>
                                    </div>

                                    <div style="width: 5%;">
                                    </div>
                                </div>
                                <draggable :list=item.backlogs group="backlogs" itemKey="id" :scroll-sensitivity="150"
                                    :disabled="false" :force-fallback="true">
                                    <template #item="{ element }">

                                        <div @mouseover="mostrarBotao(element.id, true)"
                                            @mouseleave="mostrarBotao(element.id, false)" class="linha-tabela"
                                            :style="{ 'cursor': (perfil != 1 ? '' : 'all-scroll') }"
                                            style="width: 100%;display: flex;border-bottom: 1px solid var(--cor-separador); padding-block: 0.5rem ;align-items: center">

                                            <div style="width: 10%; text-align: center;">
                                                <span style="white-space: nowrap;">{{
                                                    element.codigo }}</span>
                                            </div>
                                            <div style="width: 20%; height: 3rem; text-align: left; cursor: pointer; overflow: hidden; display: flex; align-items: center; padding-inline: 5px;"
                                                @click.stop @click="abrirModalEditarBacklog(element.id, item.id, false)"
                                                :title="element.descricao">
                                                <div
                                                    style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                                                    {{ element.descricao }}
                                                </div>
                                            </div>
                                            <div style="width: 5%; text-align: center;">
                                                <select v-model="element.HP" class="HP" :disabled="false"
                                                    @change="editarBacklog('HP', element.id, element.HP)"
                                                    style="width: 2.5rem; height: 2.5rem;text-align: center; border-radius: 50%; padding: 0; opacity: 1;">
                                                    <option hidden>0</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>5</option>
                                                    <option>8</option>
                                                    <option>13</option>
                                                    <option>21</option>
                                                    <option>34</option>
                                                    <option>55</option>
                                                    <option>89</option>
                                                    <option>144</option>
                                                    <option>233</option>
                                                </select>
                                            </div>

                                            <div style="width: 17%; text-align: center;">
                                                <select v-model="element.responsavel_id" :disabled="false"
                                                    style="min-width: max-content; border: none; background-color: transparent; opacity: 1;"
                                                    @change="editarBacklog('responsavel_id', element.id, element.responsavel_id)">
                                                    <option hidden>Responsável</option>
                                                    <option v-for=" item in gerente" :key="item.id" :value="item.id"
                                                        :hidden="!subordinados.includes(item.id)">
                                                        {{ nomeEsobrenome(item.nomeCompleto) }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div style="width: 12.5%; text-align: center;">
                                                <input :disabled="false"
                                                    style="background-color: transparent;cursor: pointer; width: 7rem; text-align: left; padding-inline: 0rem; border: none; opacity: 1; "
                                                    type="date"
                                                    @change="editarBacklog('dtInicio', element.id, (element.dtInicio + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))"
                                                    v-model="element.dtInicio">
                                            </div>

                                            <div style="width: 12.5%; text-align: center;">
                                                <input :disabled="false"
                                                    style="background-color: transparent;cursor: pointer; width: 7rem; text-align: left; padding: 0rem; border: none; opacity: 1;"
                                                    type="date" :min="element.dtInicio"
                                                    @change="editarBacklog('dtFim', element.id, (element.dtFim + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))"
                                                    v-model="element.dtFim">
                                            </div>

                                            <div style="width: 18%; text-align: center;">
                                                <div style="position: relative;">
                                                    <v-progress-linear
                                                        :color="(element.status == 'Cancelada') ? 'var(--cor-erro)' : (element.status == 'Concluído') ? 'var(--cor-sucesso)' : (element.status == 'Pendente') ? 'var(--cor-alerta)' : (element.status == 'Em andamento' || 'Em andamento 25%' || 'Em andamento 50%' || 'Em andamento 75%') ? 'var(--cor-ok)' : 'red'"
                                                        :model-value="element.status == 'Concluído' || element.status == 'Cancelada' ? 100 : element.status == 'Pendente' ? 100 : element.status.split(' ')[2] ? element.status.split(' ')[2].slice(0, 2) : 2"
                                                        style="border: 1px solid var(--cor-fonte-fraca); border-radius: 25px; height: 2rem;">
                                                    </v-progress-linear>
                                                    <select :title="element.status"
                                                        :disabled="perfil != 1 && parseInt(element.responsavel_id) !== parseInt(idUsuario) && false"
                                                        :style="{ 'color': (element.status == 'Concluído') ? 'var(--cor-bg)' : 'black' }"
                                                        style="opacity: 1;border: none; padding-left: 0.5rem;position: absolute; top: 0; left: 0rem; outline: none; border-radius: 25px ;height: 2rem ;text-align: center; background-color: transparent; padding-top: 0rem; padding-bottom: 0rem;"
                                                        @change="editarBacklog('status', element.id, element.status)"
                                                        v-model="element.status">
                                                        <option value="" hidden>Status</option>
                                                        <option style="color: rgb(255, 145, 0);" value="Pendente">A
                                                            iniciar</option>
                                                        <option style="color: rgb(0, 47, 255);" :value="'Em andamento'">
                                                            Em andamento 0%</option>
                                                        <option style="color: rgb(0, 47, 255);">Em andamento 25%
                                                        </option>
                                                        <option style="color: rgb(0, 47, 255);">Em andamento 50%
                                                        </option>
                                                        <option style="color: rgb(0, 47, 255);">Em andamento 75%
                                                        </option>
                                                        <option style="color: rgb(0, 192, 0);">Concluído</option>
                                                        <option style="color: var(--cor-erro);">Cancelada</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div style="width: 5%; text-align: center;">
                                                <v-menu>
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn :id="'botaoEdicao' + element.id"
                                                            style="visibility: hidden; width: 2rem; height: 2rem; background-color: transparent; border: 1px solid var(--cor-separador); color: var(--cor-fonte);"
                                                            class="botaoAdicionarSprint acao-secundaria"
                                                            icon="mdi-dots-horizontal" v-bind="props"></v-btn>
                                                    </template>

                                                    <v-list
                                                        style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                        <v-list-item style="margin: 0.2rem;"
                                                            @click="abrirModalEditarBacklog(element.id, item.id, false)">
                                                            Editar Tarefa
                                                        </v-list-item>
                                                        <v-list-item :disabled="false">
                                                            <v-menu>

                                                                <template v-slot:activator="{ props }">
                                                                    <span
                                                                        style="color: var(--cor-erro);margin: 0.2rem; cursor: pointer;"
                                                                        v-bind="props">Excluir
                                                                        Tarefa</span>
                                                                </template>

                                                                <v-list
                                                                    style="background-color: var(--cor-bg); color: var(--cor-fonte);">
                                                                    <v-list-item>
                                                                        <div>
                                                                            <h3>Tem certeza? Esta ação é Irreversível!
                                                                            </h3>
                                                                            <div class="submit">
                                                                                <Button
                                                                                    @click="apagarBacklog(element.id)">Excluir</Button>
                                                                                <button
                                                                                    class="acao-secundaria">Cancelar</button>
                                                                            </div>
                                                                        </div>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </div>

                                    </template>
                                </draggable>
                                <br>
                                <div style="display: flex; padding-left: 0.2rem; border-radius: 5px; width: 100%; align-content: center;"
                                    v-if="true" :id="item.id">
                                    <strong
                                        style="border: 1px solid var(--cor-separador); border-radius: 5px; padding: 0.3rem; width: 6rem; text-align: center; align-content: center ;height: 3rem;">
                                        {{ this.somenteBacklogs().length !== 0 ? 'Tarefa - ' +
                                            (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1) :
                                            'Tarefa - 1' }}
                                    </strong>
                                    <input type="text" placeholder="O que será feito?"
                                        style="height: 3rem; margin: 0; margin-left: 5px;"
                                        @keyup.enter="criarBacklog(item.id, $event.target.value), $event.target.value = ''"
                                        :id="'inputNovaTarefa' + item.id">
                                </div>
                            </div>
                            <div style="text-align: center; display: none;" :id="'pontos' + item.nome"
                                @click="ocultarPlano(item.nome)" class="ocultar">
                                <i class="bi bi-grip-horizontal"></i>
                            </div>
                        </div>
                        <br>
                        <div class="alinha-centro" style="margin-bottom: 1rem;" v-if="item.nome == 'Backlogs' && true">
                            <button @click="criarNovaSprint" class="acao-secundaria alinha-v">Nova
                                Sprint
                                <i class="bi bi-plus" style="font-size: 20px;"></i></button>
                        </div>
                    </div>
                    <div class="loading" id="loading">
                        <div></div>
                    </div>
                    <br>
                </div>

                <div class="modal-mask" @click="pdfUrl = null" v-if="pdfUrl">
                    <div style="height: 95vh; width: 55rem; background-color: white; border-radius: 5px;">
                        <iframe :src="pdfUrl" style="width: 100%; height: 100%;"></iframe>
                    </div>
                </div>

                <!-- MODAL INICIAR SPRINT-->
                <div class="modal-mask" v-if="showIniciarSprint" @click="fecharModalFora">
                    <div class="jm" style="height: min-content; padding: 2rem; width: 50rem;">
                        <div style="display: flex; justify-content: center">
                            <h2>Iniciar Sprint</h2>
                        </div>
                        <br>
                        <div style="width: 100%; display: flex; justify-content: center;">
                            <div style="width: 50%;">
                                <label>Data prevista de termino:</label>
                                <input :class="{ shake: disabled }" v-model="dataTerminoSprint" id="dataTermino"
                                    class="form-control" type="date">
                            </div>
                            <div style="align-content: end; margin-left: 1rem;">
                                <button class="button" @click="iniciarSprint()">
                                    Iniciar Sprint</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END MODAL SPRINT-->

                <!-- MODAL PERSONALIZAR DATAS-->
                <div class="modal-mask" v-if="showEditarSprint" @click="fecharModalFora">
                    <div class="jm" style="height: min-content; padding: 2rem; width: 50rem;">
                        <div style="width: 100%;">
                            <div style="display: flex">
                                <div style="width: 50%;">
                                    <label>Data prevista de inicio:</label>
                                    <input :class="{ shake: disabled }" v-model="sprintEditada.dtInicio" id="dataInicio"
                                        class="form-control" type="date">
                                    <!-- :min="new Date().toISOString().split('T')[0]" -->
                                </div>
                                <div style="width: 50%; margin-left: 0.5rem">
                                    <label>Data prevista de termino:</label>
                                    <input :class="{ shake: disabled }" v-model="sprintEditada.dtTermino"
                                        id="dataTermino" class="form-control" type="date">
                                    <!-- :min="new Date().toISOString().split('T')[0]"  -->
                                </div>
                            </div>
                            <div class="submit">
                                <button @click="abrirModaeEditarSprint(this.idSprint, true)">
                                    Salvar</button>
                                <button class="acao-secundaria" @click="showEditarSprint = false">
                                    Cancelar</button>
                            </div>
                        </div>

                    </div>
                </div>
                <!--END MODAL SPRINT-->

                <!-- MODAL EDITAR BACKLOG-->
                <div class="modal-mask" v-if="showEditarBacklog" @click="fecharModalFora">
                    <div class="jm" style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;">
                        <div>
                            <h3>Editar: {{ backlogeditado.codigo }} </h3>
                        </div>
                        <hr>
                        <br>
                        <div class="grid-2">
                            <div>
                                <label>Descrição</label>
                                <textarea v-model="backlogeditado.descricao" :disabled="false"
                                    @focusout="editarBacklog('descricao', backlogeditado.id, backlogeditado.descricao)"></textarea>
                            </div>

                            <div>
                                <label>Responsável</label>
                                <select v-model="backlogeditado.responsavel_id" :disabled="false"
                                    @change="editarBacklog('responsavel_id', backlogeditado.id, backlogeditado.responsavel_id)">
                                    <option :hidden="!subordinados.includes(item.id)" v-for=" item in gerente "
                                        :key="item.id" :value="item.id">
                                        {{ item.nomeCompleto }}
                                    </option>
                                </select>
                            </div>

                            <div class="grid-2">
                                <div>
                                    <label>Inicio Previsto</label>
                                    <input v-model="backlogeditado.dtInicio" type="date" :disabled="false"
                                        @change="editarBacklog('dtInicio', backlogeditado.id, (backlogeditado.dtInicio + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Fim Previsto</label>
                                    <input v-model="backlogeditado.dtFim" type="date" :disabled="false"
                                        @change="editarBacklog('dtFim', backlogeditado.id, (backlogeditado.dtFim + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Inicio Real</label>
                                    <input v-model="backlogeditado.dtInicioReal" type="date" :disabled="false"
                                        @change="editarBacklog('dtInicioReal', backlogeditado.id, (backlogeditado.dtInicioReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                                <div>
                                    <label>Fim Real</label>
                                    <input v-model="backlogeditado.dtFimReal" type="date" :disabled="false"
                                        @change="editarBacklog('dtFimReal', backlogeditado.id, (backlogeditado.dtFimReal + ' ' + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`))">
                                </div>
                            </div>

                            <div>
                                <label>Observações</label>
                                <textarea v-model="backlogeditado.obs" rows="6"
                                    @focusout="editarBacklog('obs', backlogeditado.id, backlogeditado.obs)"></textarea>
                            </div>
                            <div class="col-2" style="margin-bottom: 0;">
                                <span>
                                    Anexos:
                                </span>
                                <input style="display: none;" ref="fileInput" class="form-control form-control-sm"
                                    type="file" @change="handleFileUpload">

                                <ul style="list-style: none; margin-bottom: 0;" class="grid-3">
                                    <li v-for="item in backlogeditado.anexos" :key="item"
                                        @mouseover="mostrarBotaoExcluirAnexo(item.id, true)"
                                        @mouseleave="mostrarBotaoExcluirAnexo(item.id, false)">
                                        <div class="anexo">
                                            <a :href="urlFoto.caminhoFoto + item.path" target="_blank">
                                                {{ item.nome }}
                                            </a>
                                            <i @click="excluirAnexo(item.id)" :id="'botaoExcluir' + item.id"
                                                style="color: red; visibility: hidden;"
                                                class="bi bi-trash-fill botaoAdicionarSprint"></i>
                                        </div>
                                    </li>
                                    <div @click="openFileInput" style="cursor: pointer"
                                        v-if="entregaSelecionada.coordenador_id == idUsuario"
                                        class="anexo botaoAdicionarSprint">
                                        Adicionar <i title="Adicionar anexo" style="justify-self: right;"
                                            class="bi bi-plus-circle"></i>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--END MODAL -->

                <!-- MODAL ADICIONAR ENTREGA-->
                <div class="modal-mask" v-if="showNovaEntrega" @click="fecharModalFora">
                    <div class="jm" style="height: max-content; width: 50rem; padding: 1rem 2rem; border-radius: 5px;">
                        <h3>
                            Nova entrega
                        </h3>
                        <hr>
                        <br>
                        <div class="grid-2">
                            <div>
                                <label>Nome da entrega</label>
                                <input :class="{ shake: disabled }" id="novaEntrega.nome" v-model="novaEntrega.nome"
                                    type="text" />
                            </div>
                            <div>
                                <label>Coordenador</label>
                                <select :class="{ shake: disabled }" id="novaEntrega.coordenador_id"
                                    v-model="novaEntrega.coordenador_id">
                                    <option v-for=" item in gerente " :key="item.id" :value="item.id">
                                        {{ item.nomeCompleto }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label>
                                    Data Inicio
                                </label>
                                <input :class="{ shake: disabled }" id="novaEntrega.dtInicio"
                                    v-model="novaEntrega.dtInicio" type="date">
                            </div>
                            <div>
                                <label>
                                    Data Fim
                                </label>
                                <input :class="{ shake: disabled }" id="novaEntrega.dtFim" v-model="novaEntrega.dtFim"
                                    type="date">
                            </div>
                        </div>
                        <div class="submit m-b"><button @click="validarCampos()">Criar</button>
                            <button @click="showNovaEntrega = false" class="acao-secundaria">Cancelar</button>
                        </div>
                        <div class="alinha-v alinha-centro">
                            <b :class="{ shake: disabled }"
                                style="margin-top: 0.5rem; margin-right: 1rem; display: none; color: var(--cor-erro);"
                                id="aviso">Preencha
                                os
                                campos destacados!</b>
                        </div>
                    </div>
                </div>
                <!--END MODAL -->
            </div>
        </section>
    </main>
</template>

<script>
import { ref } from 'vue';
import draggable from "@/vuedraggableVue";
import { api } from "roboflex-thalamus-request-handler";
import { consultarSetores } from '@/services/usuario-setor';
import { sso } from "roboflex-thalamus-sso-lib";
import { urlFoto } from "../../services/api";
import serviceFunções from "@/services/serviceFunções";
import Cookies from 'js-cookie'

export default {
    components: {
        draggable
    },

    name: "SprintsView",

    setup() {
        const fileInput = ref(null);

        const openFileInput = () => {
            fileInput.value.click();
        };

        const somarHP = (dados) => {
            return serviceFunções.somarHP(dados);
        };

        const nomeEsobrenome = (nome) => {
            return serviceFunções.nomeEsobrenome(nome);
        };

        const abreviarMes = (dataString, mandarAno) => {
            return serviceFunções.abreviarMes(dataString, mandarAno);
        };

        return { fileInput, openFileInput, urlFoto, somarHP, nomeEsobrenome, abreviarMes };
    },

    data() {
        return {
            pdfUrl: null,

            nome: null,
            pcm_codigo: null,
            pcm_id: null,
            idProjeto: null,
            perfil: null,

            novaEntrega: {
                nome: null,
                coordenador_id: null,
                dtInicio: null,
                dtFim: null
            },
            dadosProjeto: null,

            idUsuario: null,
            backlogs: [],
            novoElemento: '',
            disabled: false,
            showEditarSprint: false,
            showEditarBacklog: false,
            showIniciarSprint: false,
            showConfirmação: false,
            showNovaEntrega: false,
            teste: "",
            desativarEdicao: true,
            sprints: null,
            novaSprint: {
                id: null,
                nome: "",
                backlogs: [],
                dtTermino: null
            },
            idSprint: null,
            dataTerminoSprint: null,
            backlogeditado: null,
            idSprintBacklogEditado: null,
            gerente: [],
            coordenadores: [],
            sprintEditada: null,
            subordinados: [],
            anexos: [],
            entregas: [
            ],
            entregaSelecionada: null,
        }
    },

    watch: {
        sprints: {
            handler: 'moverBacklog',
            deep: true,
        },
    },

    created() {

        const usuarioLogado = sso.getUsuarioLogado()
        this.idUsuario = usuarioLogado.id

        const details = Cookies.get('projectDetails')
        if (details) {
            var projectDetails = JSON.parse(details)

            this.idProjeto = projectDetails.idProjeto
            this.nome = projectDetails.nome
            this.pcm_codigo = projectDetails.pcm_codigo
            this.pcm_id = projectDetails.pcm_id
            this.perfil = projectDetails.perfil

        } else {
            this.$router.push({ name: 'ControleDeProjetos' })
        }
    },

    mounted() {
        localStorage.removeItem('ultimaSprintEditada')
        this.getBacklogs(),
            this.getGerenteseSetor(),
            this.getCoordenadores()
    },

    methods: {
        getCoordenadores() {
            api.get(`usuario/lideranca/listar`)
                .then((response) => {
                    this.coordenadores = response.data
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        validarCampos() {
            var camposVazios = []
            if (!this.novaEntrega.nome) camposVazios.push('novaEntrega.nome');
            if (!this.novaEntrega.coordenador_id) camposVazios.push('novaEntrega.coordenador_id');
            if (!this.novaEntrega.dtInicio) camposVazios.push('novaEntrega.dtInicio');
            if (!this.novaEntrega.dtFim) camposVazios.push('novaEntrega.dtFim');

            camposVazios.forEach(item => {
                document.getElementById(item).style.border = 'solid 1px var(--cor-erro)';
                document.getElementById('aviso').style.display = '';
                this.disabled = true
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
                return
            });

            if (camposVazios.length == 0) {
                this.gravarNovaEntrega()
                this.novaEntrega = {
                    nome: null,
                    coordenador_id: null,
                    dtInicio: null,
                    dtFim: null
                };
                this.showNovaEntrega = false
            }
        },

        atualizarEntrega(itemEditado, valor) {
            api.put(`projeto/entrega/atualizar`, {
                entrega_id: this.entregaSelecionada.id,
                [itemEditado]: valor,
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        excluirEntrega() {
            api.post(`projeto/entrega/excluir`, {
                entrega_id: this.entregaSelecionada.id,
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.entregaSelecionada = this.entregas[0]
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        gravarNovaEntrega() {
            api.post(`projeto/entrega/gravar`, {
                projeto_id: this.idProjeto,
                nome: this.novaEntrega.nome,
                coordenador_id: this.novaEntrega.coordenador_id,
                dtInicio: this.novaEntrega.dtInicio,
                dtFim: this.novaEntrega.dtFim
            })
                .then((response) => {
                    this.entregas.push(response.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async loadPdf() {
            if (this.pcm_id !== 'null') {
                try {
                    const response = await api.get(`pcm/${this.pcm_id}/pdf`, {
                        responseType: 'arraybuffer', // Isso é importante para receber o PDF como dados binários
                    });

                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    this.pdfUrl = URL.createObjectURL(blob);
                } catch (error) {
                    console.error('Erro ao carregar o PDF', error);
                }
            }
        },

        handleFileUpload() {
            const fileInput = this.$refs.fileInput;

            if (fileInput && fileInput.files.length > 0) {
                const formData = new FormData();
                formData.append('sprintTarefa_id', this.backlogeditado.id);

                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('anexos[]', fileInput.files[i]);
                }

                api.post(`sprintTarefa/anexo/adicionar`, formData)


                    .then(response => {
                        this.backlogeditado.anexos.push({
                            path: response.data.anexos_salvos[0].path,
                            nome: response.data.anexos_salvos[0].nome,
                            id: response.data.anexos_salvos[0].id
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },

        excluirAnexoProjeto(id) {
            api.delete(`projeto/anexo/remover/${id}`);
            this.anexos = this.anexos.filter(anexo => anexo.id !== id)
        },

        handleProjetoFileUpload() {
            const fileInput = this.$refs.fileInput;

            if (fileInput && fileInput.files.length > 0) {
                const formData = new FormData();
                formData.append('projeto_id', this.idProjeto);

                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('anexos[]', fileInput.files[i]);
                }
                api.post(`projeto/anexo/adicionar`, formData)
                    .then(response => {
                        this.anexos.push({
                            path: response.data.anexos_salvos[0].path,
                            nome: response.data.anexos_salvos[0].nome,
                            id: response.data.anexos_salvos[0].id
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },

        excluirAnexo(id) {
            api.delete(`sprintTarefa/anexo/remover/${id}`)
            this.backlogeditado.anexos = this.backlogeditado.anexos.filter(anexo => anexo.id !== id)
        },

        mostrarBotaoExcluirAnexo(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoExcluir' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoExcluir' + id).style.visibility = 'hidden'
            }
        },

        somenteBacklogs() {
            if (this.sprints !== null) {
                var sprint = this.sprints.map((item) => item.backlogs).flat()
                sprint.sort((a, b) => b.id - a.id);
                return sprint
            }
            return 'nada'
        },

        moverBacklog() {
            if (this.sprints !== null) {
                var data = this.sprints;
                var itemProcurado = JSON.parse(sessionStorage.getItem('tarefaMovida'));
                if (itemProcurado != null) {
                    var idBacklog = itemProcurado.id;
                }

                for (let i = 0; i < data.length; i++) {
                    const projeto = data[i];
                    const backlogs = projeto.backlogs;

                    for (let j = 0; j < backlogs.length; j++) {
                        const backlog = backlogs[j];
                        if (JSON.stringify(backlog) === JSON.stringify(itemProcurado)) {
                            var idSprint = this.sprints[i].id
                        }
                    }
                }

                api.put(`sprintTarefa/atualizar/${idBacklog}`, {
                    sprint_id: idSprint,
                })
                    .then(() => {
                        this.getBacklogs
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

        },

        async getGerenteseSetor() {
            try {
                const { usuarios, setores } = await consultarSetores();
                this.gerente = usuarios;
                this.setores = setores;
            } catch (error) {
                console.error(error);
            }
        },

        mostrarBotao(id, mostrar) {
            if (mostrar == true) {
                document.getElementById('botaoEdicao' + id).style.visibility = ''
            }
            if (mostrar == false) {
                document.getElementById('botaoEdicao' + id).style.visibility = 'hidden'
            }
        },

        getBacklogs() {
            document.getElementById('loading').style.display = ''
            api.post(`projeto/entrega/listar`, {
                projeto_id: this.idProjeto
            })
                .then((response) => {
                    this.anexos = response.data.anexosProjeto;
                    this.dadosProjeto = response.data.projetos[0];
                    this.entregas = response.data.projetos[0].entregas

                    if (this.entregaSelecionada == null) {
                        this.entregaSelecionada = this.entregas[0]
                    }


                    api.post(`setor/responsavel/usuario`, { usuario_id: this.entregaSelecionada.coordenador_id })

                        .then((response) => {
                            this.subordinados = response.data.map((item) => item.usuario_id)

                            api.post(`projeto/entrega/buscar`, {

                                entrega_id: this.entregaSelecionada.id
                            })
                                .then((response) => {
                                    function compararSprints(a, b) {
                                        if (a.nome === "Backlogs") {
                                            return -1;
                                        } else if (b.nome === "Backlogs") {
                                            return 1;
                                        }
                                        return b.id - a.id;
                                    }

                                    document.getElementById('loading').style.display = 'none'

                                    this.sprints = response.data.sprints.sort(compararSprints);
                                    var id = localStorage.getItem('ultimaSprintEditada');
                                    if (id) {
                                        setTimeout(() => {
                                            document.getElementById('inputNovaTarefa' + id).focus();
                                            localStorage.removeItem('ultimaSprintEditada');
                                        }, 250);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        definirInicioFimReal(idBacklog, status) {

            let data = new Date().toISOString().split('T')[0] + " " + `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;

            if (status == 'Em andamento' || status == 'Em andamento 25%' || status == 'Em andamento 50%' || status == 'Em andamento 75%') {

                api.put(`sprintTarefa/atualizar/${idBacklog}`, {

                    usuario_id: this.idUsuario,
                    dtInicioReal: data,
                    dtFimReal: null
                })
                    .then(() => {

                        return this.getBacklogs()
                    })

            } if (status == 'Concluído') {

                api.put(`sprintTarefa/atualizar/${idBacklog}`, {

                    usuario_id: this.idUsuario,
                    dtFimReal: data

                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } if (status == 'Pendente') {

                api.put(`sprintTarefa/atualizar/${idBacklog}`, {

                    usuario_id: this.idUsuario,
                    dtInicioReal: null,
                    dtFimReal: null
                })
                    .then(() => {
                        return this.getBacklogs()
                    })

            } else {
                return this.getBacklogs()
            }


        },

        abrirModalEditarBacklog(idBacklog, idSprint) {
            this.showEditarBacklog = true;

            let sprint = this.sprints.find(sprint => sprint.id === idSprint);

            this.backlogeditado = sprint.backlogs.find(backlog => backlog.id === idBacklog);
        },

        editarBacklog(itemAlterado, idBacklog, novoValor) {

            // axios.put(`http://10.100.0.5:8000/api/sprintTarefa/atualizar/${idBacklog}`, {
            api.put(`sprintTarefa/atualizar/${idBacklog}`, {

                usuario_id: this.idUsuario,
                [itemAlterado]: novoValor
            })
                .then(() => {
                    if (itemAlterado == 'status') {
                        return this.definirInicioFimReal(idBacklog, novoValor)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        editarSprint(itemAlterado, idSprint, novoValor) {

            // axios.put(`http://10.100.0.5:8000/api/sprint/atualizar/${idSprint}`, {
            api.put(`sprint/atualizar/${idSprint}`, {

                [itemAlterado]: novoValor,
            })
                .then(() => {
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        ocultarPlano(nome) {
            var idBotao = 'botaoOcultar' + nome;
            var idPontos = 'pontos' + nome

            if (document.getElementById(nome).style.display !== "none") {
                document.getElementById(idBotao).className = "bi bi-eye";
                document.getElementById(nome).style.display = "none";
                document.getElementById(idPontos).style.display = "";
            } else {
                document.getElementById(idBotao).className = "bi bi-eye-slash";
                document.getElementById(nome).style.display = "";
                document.getElementById(idPontos).style.display = "none";
            }
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.showEditarBacklog = false;
                this.showIniciarSprint = false;
                this.showConfirmação = false;
                this.showEditarSprint = false;
                this.showNovaEntrega = false
            }

        },

        abrirModalSprint() {
            this.showCriarSprint = true
        },

        fecharModal() {
            this.showIniciarSprint = false;
        },

        abrirModalIniciarSprint(id) {
            this.idSprint = id
            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia

            var sprint = this.sprints.find(sprint => sprint.id === this.idSprint)
            if (sprint.dtTermino !== null) {

                // axios.put(`http://10.100.0.5:8000/api/sprint/atualizar/${this.idSprint}`, {
                api.put(`sprint/atualizar/${this.idSprint}`, {


                    dtInicio: null,
                    dtTermino: data
                })
                    .then(() => {
                        this.idSprint = null;
                        this.getBacklogs();
                        this.fecharModal()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                this.showIniciarSprint = true;
            }

        },

        abrirModaeEditarSprint(id, salvar) {
            this.idSprint = id
            var sprint = this.sprints.find(sprint => sprint.id === this.idSprint)
            if (salvar == false) {
                this.showEditarSprint = true;
                this.sprintEditada = sprint
            } else {
                // axios.put(`http://10.100.0.5:8000/api/sprint/atualizar/${this.idSprint}`, {
                api.put(`sprint/atualizar/${this.idSprint}`, {


                    dtInicio: this.sprintEditada.dtInicio,
                    dtTermino: this.sprintEditada.dtTermino
                })
                    .then(() => {
                        this.idSprint = null;
                        this.getBacklogs();
                        this.showEditarSprint = false;
                    })
                    .catch((error) => {
                        console.error(error);
                    });

            }

        },

        iniciarSprint() {
            let data = new Date()
            let ano = data.getFullYear();
            let mes = (data.getMonth() + 1);
            if (mes < 10) {
                mes = "0" + mes
            }
            let dia = data.getDate();
            if (dia < 10) {
                dia = "0" + dia
            }
            data = ano + '-' + mes + '-' + dia

            if (this.dataTerminoSprint == null) {
                document.getElementById('dataTermino').style.border = '1px solid red';
                this.disabled = true
                setTimeout(() => {
                    this.disabled = false
                }, 1500)
                return
            }

            // axios.put(`http://10.100.0.5:8000/api/sprint/atualizar/${this.idSprint}`, {
            api.put(`sprint/atualizar/${this.idSprint}`, {

                dtInicio: data,
                dtTermino: this.dataTerminoSprint,
            })
                .then(() => {
                    this.idSprint = null;
                    this.getBacklogs();
                    this.fecharModal()
                })
                .catch((error) => {
                    console.error(error);
                });

        },

        criarNovaSprint() {

            var nomeSprint = "Sprint - 1";

            if (this.sprints[this.sprints.length - 1].nome != "Backlogs") {
                nomeSprint = 'Sprint - ' + (parseInt((this.sprints[1].nome).match(/\d+$/)[0]) + 1);
            } else {
                nomeSprint = 'Sprint - 1';
            }
            api.post(`sprint/cadastrar`, {

                nome: nomeSprint,
                projeto_id: this.idProjeto,
                entrega_id: this.entregaSelecionada.id
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        criarBacklog(id, descricao) {

            if (this.somenteBacklogs().length !== 0) {

                // axios.post(`http://10.100.0.5:8000/api/sprintTarefa/cadastrar`, {
                api.post(`sprintTarefa/cadastrar`, {

                    sprint_id: id,
                    codigo: 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                    descricao: descricao
                })
                    .then((response) => {
                        localStorage.setItem('ultimaSprintEditada', id)
                        // this.getBacklogs();

                        var novoItem = {
                            "id": response.data.id,
                            "sprint_id": id,
                            "codigo": 'Tarefa - ' + (parseInt((this.somenteBacklogs()[0].codigo).match(/\d+$/)[0]) + 1),
                            "descricao": descricao,
                            "HP": 0,
                            "responsavel_id": 0,
                            "status": "Pendente",
                            "dtInicio": null,
                            "dtFim": null,
                            "dtInicioReal": null,
                            "dtFimReal": null,
                            "responsavel": null,
                            "anexos": []
                        };

                        const sprint = this.sprints.find(item => item.id === id)
                        sprint.backlogs.push(novoItem)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                api.post(`sprintTarefa/cadastrar`, {
                    sprint_id: id,
                    codigo: 'Tarefa - 1',
                    descricao: descricao
                })
                    .then(() => {
                        sessionStorage.setItem('ultimaSprintEditada', id)
                        this.getBacklogs();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

        },

        apagarBacklog(idBacklog) {

            api.put(`sprintTarefa/excluir/${idBacklog}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        apagarSprint(id) {
            api.put(`sprint/excluir/${id}`, {
                usuario_id: this.idUsuario
            })
                .then(() => {
                    this.getBacklogs()
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
}
</script>

<style scoped>
.linha-tabela:hover {
    background-color: var(--cor-primaria-fraca);
}

* {
    user-select: none;
}

select:disabled,
textarea:disabled,
input:disabled {
    opacity: 1;
    cursor: not-allowed;
}
</style>